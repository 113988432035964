<template>
    <div>
        <card-header title="Settings" icon="fa-cogs"/>

        <card-list>

        </card-list>
    </div>
</template>

<script>
    import CardHeader from "../../TIER/components/CardHeader";
    import CardList from "../../TIER/components/CardList";

    export default {
        name: "Communication",
        props: ['card'],
        components: {CardList, CardHeader},
        data: function() {
            return {
                users: [
                    {
                        first_name: 'Derek',
                        last_name: 'Thomas'
                    }
                ]
            }
        }
    };
</script>

<style lang="scss" scoped>

</style>
